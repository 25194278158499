import React from "react";
import styled from "styled-components";
import Layout from "../providers/Layout";

const Container = styled.div`
  max-width: 1240px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.p`
  margin: 50px 0;
  font-size: 100px;
  font-weight: 900;
`;
const Text = styled.p`
  margin: 0 0 100px 0;
  font-size: 2.5rem;
  font-weight: 900;
`;

const Page404 = () => {
  return (
    <>
      <Layout
        navbarLinks={[]}
        importantArticles={{ results: [] }}
        metatagsData={{ title: "404 - portal agrarlex.pl" }}
      >
        <Container>
          <Header>404</Header>
          <Text>Nie znaleziono strony</Text>
        </Container>
      </Layout>
    </>
  );
};

export default Page404;
